<template>
  <SlModal
    :id="id"
    :loading="isLoading"
    no-header
    @on-enter="handleClose"
  >
    <div class="modal-content system-details">
      <div class="system-details__logo">
        <icon
          data="@icons/logo.svg"
          class="size-logo"
        />
      </div>
      <div class="system-details__title heading-4-sb grey-90">
        {{ $t('Main.Ui.acProjectStats') }}
      </div>
      <div class="system-details__block">
        <SlInfoItem
          v-for="label in projectStatsLabels"
          :key="label.value"
          :label="label.text"
        >
          <template #value>
            <span class="system-details__block-value body-1-md grey-90">
              {{ projectStatsValues[label.value] || '—' }}
            </span>
          </template>
        </SlInfoItem>
      </div>
    </div>
    
    <template #footer>
      <SlModalFooter>
        <SlButton
          full-width
          @click.stop="handleClose"
        >
          {{ $t('Common.Ok') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { projectStatsLabelsConfig } from '@/config/project';

export default {
  name: 'ProjectStatsModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      projectStats: state => state.project.project.stats
    }),
    projectStatsLabels() {
      return projectStatsLabelsConfig(this);
    },
    projectStatsValues() {
      const values = this.projectStats;

      if (values?.importInfo && Array.isArray(values?.importInfo)) {
        values.importInfo = values.importInfo.join(', ');
      }

      return values;
    }
  },
  methods: {
    handleClose() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/system-details-modal.scss";

.system-details {
  &__title {
    margin: unset;
  }

  &__block {
    padding: unset;
  }
}
</style>
