<template>
  <SidebarDropdown
    v-bind="$attrs"
    @hide="resetExpand"
    @toggle="handleToggle"
  >
    <template
      v-for="(_, slot) in $scopedSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
    <template #content>
      <SlOverlay :show="isLoading">
        <SlLoader
          :text="false"
          size="xs"
        />
      </SlOverlay>
      <div class="dropdown-menu">
        <div
          v-if="runningPermanentProjects.length"
          class="dropdown-menu__block"
          :class="{
            'dropdown-menu__block--scroll': showMorePermanentVisible
          }"
        >
          <div class="dropdown-menu__title dropdown-menu__title--sm">
            {{ $t('Web.Sidebar.RunningProjects') }}
          </div>
          <SidebarDropdownButton
            v-for="project in runningPermanentProjects"
            :key="project.id"
            :label="project.name"
            :selected="project.id === projectId"
            @click="handleSwitchProject(project)"
          />
          <div
            v-if="showMorePermanentVisible"
            class="dropdown-menu__title dropdown-menu__title--center dropdown-menu__title--sticky"
            @click="toggleShowPermanent"
          >
            {{ showMoreLessTitle[morePermanent] }}
            <icon
              data="@icon/arrow-down.svg"
              class="size-16"
              :class="{
                'rotate-180': morePermanent
              }"
            />
          </div>
        </div>
        <div
          v-if="runningTemporaryProjects.length"
          class="dropdown-menu__block"
          :class="{
            'dropdown-menu__block--scroll': showMoreTemporaryVisible
          }"
        >
          <div class="dropdown-menu__title dropdown-menu__title--sm">
            {{ $t('Web.Project.Label.Temp') }}
          </div>
          <SidebarDropdownButton
            v-for="project in runningTemporaryProjects"
            :key="project.id"
            :label="project.name"
            :selected="project.id === projectId"
            @click="handleSwitchProject(project)"
          />
          <div
            v-if="showMoreTemporaryVisible"
            class="dropdown-menu__title dropdown-menu__title--center dropdown-menu__title--sticky"
            @click="toggleShowTemporary"
          >
            {{ showMoreLessTitle[moreTemporary] }}
            <icon
              data="@icon/arrow-down.svg"
              class="size-16"
              :class="{
                'rotate-180': moreTemporary
              }"
            />
          </div>
        </div>
        <div class="dropdown-menu__block">
          <SidebarDropdownButton
            :label="$t('Web.Project.Label.ViewAll')"
            icon="project-switch"
            @click="goToProjectsList"
          />
          <SidebarDropdownButton
            v-if="$sl_isUserAdmin"
            :label="$t('Web.Project.Label.Create')"
            icon="plus"
            @click="handleCreateProject('toolbar')"
          >
            <template
              v-if="isRestrictedFromAddingProject"
              #append-inner
            >
              <icon
                data="@icons/upgrade-plan.svg"
                class="fill-off size-16"
              />
            </template>
          </SidebarDropdownButton>
        </div>
      </div>
    </template>
  </SidebarDropdown>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SidebarDropdown from '@/components/SidebarMenu/Dropdown/SidebarDropdown.vue';
import SidebarDropdownButton from '@/components/SidebarMenu/Dropdown/SidebarDropdownButton.vue';
import { access } from '@/mixins/access';
import { project } from '@/mixins/project';
import { mainRoutes, routeNames } from '@/config/router/router.config';

const LIST_SIZE = 5;

export default {
  name: 'ProjectSwitcherDropdown',
  components: {
    SidebarDropdown,
    SidebarDropdownButton
  },
  mixins: [access, project],
  data() {
    return {
      isLoading: true,
      permOffset: LIST_SIZE,
      tempOffset: LIST_SIZE,
      morePermanent: false,
      moreTemporary: false,
      showMoreLessTitle: {
        [true]: this.$t('Web.Project.Label.Less'),
        [false]: this.$t('Web.Project.Label.More')
      }
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      projects: (state) => state.manageProjects.projectList
    }),
    ...mapGetters({
      isFreePlan: 'account/isFreePlan',
      runningProjects: 'manageProjects/runningProjects'
    }),
    showMorePermanentVisible() {
      return this.runningProjects.permanent.length > LIST_SIZE;
    },
    showMoreTemporaryVisible() {
      return this.runningProjects.temporary.length > LIST_SIZE;
    },
    runningPermanentProjects() {
      return this.runningProjects.permanent.slice(0, this.permOffset);
    },
    permanentProjects() {
      return this.projects?.permanent || [];
    },
    runningTemporaryProjects() {
      return this.runningProjects.temporary.slice(0, this.tempOffset);
    }
  },
  methods: {
    ...mapActions({
      openProject: 'manageProjects/openProject',
      getProjectList: 'manageProjects/getProjectList'
    }),
    async handleToggle(val) {
      if (!val) {
        this.isLoading = true;

        return;
      }

      try {
        this.isLoading = true;

        await this.getProjectList({ cache: true });
      } finally {
        this.isLoading = false;
      }
    },
    resetExpand() {
      this.morePermanent = false;
      this.moreTemporary = false;
    },
    toggleShowPermanent() {
      this.permOffset = this.morePermanent
        ? LIST_SIZE
        : this.runningProjects.permanent.length;

      this.morePermanent = !this.morePermanent;
    },
    toggleShowTemporary() {
      this.tempOffset = this.moreTemporary
        ? LIST_SIZE
        : this.runningProjects.temporary.length;

      this.moreTemporary = !this.moreTemporary;
    },
    async handleSwitchProject({ id: pid, type: type }) {
      if (pid === this.projectId) {
        return;
      }

      const isMainRoute = mainRoutes.includes(this.$sl_routeName);
      const redirect = isMainRoute ? this.$sl_routeName : routeNames.DEMAND;

      this.openProject({
        pid,
        type,
        redirect
      });
    },
    goToProjectsList() {
      this.$router.push({
        name: routeNames.PROJECT_LIST
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/sidebar-menu/dropdown-menu.scss";
</style>
