<template>
  <SlModal
    :id="id"
    :title="title"
    :loading="isLoading"
    persistent
    @on-enter="handleLogin"
    @created="onCreated"
    @show="onModalShow"
    @hidden="onModalHide"
  >
    <template #modals>
      <DatasourceConnectionError :id="modalsId.CONNECTOR_ERROR" />
      <ConnectionTimeoutModal :id="modalsId.BC365_CONNECTION_TIMEOUT" />
      <OAuthLoginModal :id="modalsId.BC365_OAUTH_LOGIN" />
    </template>

    <template #loader>
      <SlOverlay :show="isLoading" />
    </template>

    <ValidationObserver
      ref="bc365ConnectorObserver"
      class="modal-content bc365-connector"
    >
      <div
        v-if="isNew || !$sl_isEditingRoute"
        class="bc365-connector__row bc365-connector__row--50"
      >
        <SlControl
          v-model="connectionTypeModel"
          :options="connectionTypeOptions"
          :label="$t('Web.BC365.LabelConnectionType')"
        />
      </div>
      <template v-if="isOnPremisesConnection">
        <div class="bc365-connector__row">
          <SlValidate
            v-slot="{ invalid }"
            key="baseUrl"
            vid="baseUrl"
            rules="required|sl_url"
          >
            <SlInput
              v-model="baseUrlModel"
              :label="$t('Web.BC365.Form.URL')"
              :is-invalid="invalid"
              required
            />
          </SlValidate>
        </div>
        <div class="bc365-connector__row">
          <SlValidate
            v-slot="{ invalid }"
            key="username"
            vid="username"
            rules="required"
          >
            <SlInput
              v-model="usernameModel"
              :label="$t('Web.BC365.Form.Username')"
              :is-invalid="invalid"
              required
            />
          </SlValidate>
        </div>
        <div class="bc365-connector__row">
          <SlValidate
            v-slot="{ invalid }"
            key="password"
            vid="password"
            rules="required"
          >
            <SlPasswordInput
              v-model="passwordModel"
              :label="$t('Web.BC365.Form.Password')"
              :is-invalid="invalid"
              required
            />
          </SlValidate>
        </div>
      </template>
      <div
        v-else
        class="bc365-connector__row body-1 grey-70"
      >
        {{ cloudText }}
      </div>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleLogin">
          {{ confirmButton }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import { connection } from '@/mixins/connection';
import DatasourceConnectionError from '@/components/Modals/Connections/Connectors/Shared/DatasourceConnectionError';
import ConnectionTimeoutModal from '@/components/Modals/Shared/ConnectionTimeoutModal';
import OAuthLoginModal from '@/components/Modals/Shared/OAuthLoginModal';
import modalsId from '@/config/shared/modalsId.config';
import { connectionTypeOptions, sourceConnectData, bc365ConnectionTypes } from '@/config/connection/BC365.config';
import { sourceConnectDataFields, connectionTypes } from '@/config/connection';
import { operationInfoTypes } from '@/config/api/operations.config';
import { connectorRouteNames } from '@/config/router/router.config';
import { openLink } from '@/helpers/shared/webAPI';
import { toArray } from '@/helpers/utils/toArray';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';

export default {
  name: 'BC365ConnectorModal',
  components: {
    DatasourceConnectionError,
    ConnectionTimeoutModal,
    OAuthLoginModal
  },
  mixins: [modal, connection],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      type: connectionTypes.BUSINESS_CENTRAL,
      isNew: false,
      isReconnect: false,
      isLoading: false,
      operationId: null,
      oAuthLink: null,
      closeCallback: null
    };
  },
  computed: {
    title() {
      return this.isReconnect
        ? this.$t('Web.BC365.TitleReconnect')
        : this.$t('Web.BC365.Title');
    },
    cloudText() {
      return this.isReconnect
        ? this.$t('Web.BC365.CloudTextReconnect')
        : this.$t('Web.BC365.CloudText');
    },
    confirmButton() {
      return this.isReconnect
        ? this.$t('Web.BaseConnectors.BtnReconnect')
        : this.$t('Web.BaseConnectors.BtnLogin');
    },
    sourceConnectData() {
      return this.sourceConnectDataByType[this.type];
    },
    sourceConnectDataCache() {
      return this.sourceConnectDataCacheByType[this.type];
    },
    connectionTypeOptions() {
      return connectionTypeOptions(this);
    },
    isOnPremisesConnection() {
      return this.connectionTypeModel === bc365ConnectionTypes.ON_PREMISES;
    },
    connectionDataKey() {
      return this.isNew
        ? sourceConnectDataFields.MAIN
        : sourceConnectDataFields.CACHE;
    },
    currentData() {
      return this[this.connectionDataKey];
    },
    connectionTypeModel: {
      get() {
        return this.currentData?.type;
      },
      set(value) {
        this.setCurrentData({ key: 'type', value });
      }
    },
    baseUrlModel: {
      get() {
        return this.currentData?.baseUrl;
      },
      set(value) {
        this.setCurrentData({ key: 'baseUrl', value });
      }
    },
    usernameModel: {
      get() {
        return this.currentData?.username;
      },
      set(value) {
        this.setCurrentData({ key: 'username', value });
      }
    },
    passwordModel: {
      get() {
        return this.currentData?.password;
      },
      set(value) {
        this.setCurrentData({ key: 'password', value });
      }
    }
  },
  methods: {
    ...mapActions({
      resetConnectionDataState: 'connection/resetConnectionDataState',
      resetBC365State: 'bc365/resetState',
      fetchEnvironments: 'bc365/fetchEnvironments',
      fetchCompaniesOnPremises: 'bc365/fetchCompaniesOnPremises',
      setEnvironments: 'bc365/setEnvironments',
      setCompanies: 'bc365/setCompanies',
      subscribe: 'operations/subscribe',
      unsubscribe: 'operations/unsubscribe',
      logout: 'user/logout'
    }),
    onModalShow() {
      if (!this.isNew) {
        this.setSourceConnectData({
          field: sourceConnectDataFields.CACHE,
          type: this.type,
          value: this.sourceConnectData
        });
      }
    },
    onModalHide() {
      this.closeCallback && this.closeCallback();

      if (!this.isNew) {
        this.setSourceConnectData({
          field: sourceConnectDataFields.CACHE,
          type: this.type,
          value: sourceConnectData()
        });
      }

      this.isNew = false;
      this.isReconnect = false;
      this.isLoading = false;
      this.oAuthLink = null;
      this.operationId = null;
      this.closeCallback = null;
    },
    setCurrentData(payload) {
      this.setSourceConnectValueByKey({
        ...payload,
        type: this.type,
        field: this.connectionDataKey
      });
    },
    handleLoginCancel() {
      if (this.operationId) {
        this.unsubscribe(this.operationId);
      }

      this.isLoading = false;
      this.oAuthLink = null;
      this.operationId = null;
    },
    handleLoginTryAgain() {
      if (this.oAuthLink) {
        return openLink(this.oAuthLink, true);
      }

      this.handleCloudLogin();
    },
    goToConnectorPage() {
      if (!this.isNew && !this.$sl_isEditingRoute) {
        this.resetConnectionDataState();

        this.setSourceConnectData({
          field: sourceConnectDataFields.MAIN,
          type: this.type,
          value: this.sourceConnectDataCache
        });
      }

      if (this.isNew) {
        return this.$router.push({
          name: connectorRouteNames.CREATE_PROJECT_CONNECTOR,
          params: {
            connector: this.type
          }
        });
      }

      if (this.$sl_isEditingRoute) {
        return this.$router.push({
          name: connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
          params: {
            connector: this.type
          }
        });
      }
    },
    clearSelectedValues() {
      if (this.isNew) {
        return;
      }

      this.setRequiredValue({
        key: 'company',
        value: null
      });

      this.setRequiredValue({
        key: 'environment',
        value: null
      });
    },
    async handleCloudLogin() {
      try {
        this.isLoading = true;

        const { operationData } = await this.subscribe({
          subscriber: async() => {
            const response = await this.fetchEnvironments();

            this.operationId = response?.data?.operationId;

            return response;
          },
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              this.isLoading = false;
              this.oAuthLink = operationData.url;
              openLink(operationData.url, true);

              this.showModal(modalsId.BC365_OAUTH_LOGIN, {
                source: this.$t('Web.BC365.Title'),
                tryAgainCallback: this.handleLoginTryAgain,
                cancelCallback: this.handleLoginCancel
              });
            }
          }
        });

        const { cacheId, values } = operationData;

        this.goToConnectorPage();
        this.setCompanies(null);
        this.setEnvironments(toArray(values));

        this.setRequiredValue({
          key: 'cacheId',
          value: cacheId
        });

        this.clearSelectedValues();
        this.hideModal(this.id);
      } catch (e) {
        if (e?.code === slErrorCodes.OAUTH_TIMEOUT_ERROR) {
          return this.showModal(modalsId.BC365_CONNECTION_TIMEOUT, {
            source: this.$t('Web.BC365.Title'),
            closeCallback: () => this.hideModal(modalsId.BC365_OAUTH_LOGIN)
          });
        }

        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            title: errorMessage,
            duration: 15000
          });
        }

        this.hideModal(modalsId.BC365_OAUTH_LOGIN);

        this.logout({
          e,
          from: 'bc365/handleCloudLogin'
        });
      } finally {
        this.isLoading = false;
        this.oAuthLink = null;
        this.operationId = null;
      }
    },
    async handleOnPremisesLogin() {
      try {
        this.isLoading = true;

        const connection = await this.fetchCompaniesOnPremises({
          login: this.usernameModel,
          password: this.passwordModel,
          baseUrl: this.baseUrlModel
        });

        if (connection) {
          this.goToConnectorPage();
          this.clearSelectedValues();

          this.hideModal(this.id);
        }
      } catch {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          connector: this.$t('Web.BC365.Title')
        });
      } finally {
        this.isLoading = false;
      }
    },
    async handleLogin() {
      const valid = await this.$refs.bc365ConnectorObserver.validate();

      if (!valid) {
        return;
      }

      if (this.isOnPremisesConnection) {
        this.handleOnPremisesLogin();
      } else {
        this.handleCloudLogin();
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection/connectors/bc365/bc365-connector-modal.scss";
</style>
