<template>
  <div class="time-machine-overlay">
    <SlProgressLoader
      :progress-point="+progressPercent"
      :title="$t('Main.Ui.acStartSimulation')"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TimeMachineOverlay',
  computed: {
    ...mapState({
      progressPercent: state => state.timeMachine.progressPercent
    })
  }
};
</script>

<style lang="scss" scoped>
@import "src/style/utils/_color.scss";

.time-machine-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11000; // v-popper is 10k

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  background: rgba($white, 0.3);
}
</style>