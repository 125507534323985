export const localeSteps = (ctx) => ([
  { value: 1, label: ctx.$t('Main.TimeMachine.Days.val', {
    p1: ctx.$tc('Main.TimeMachine.Days.p1', 1, { n: 1 })
  }) },
  { value: 5, label: ctx.$t('Main.TimeMachine.Days.val', {
    p1: ctx.$tc('Main.TimeMachine.Days.p1', 5, { n: 5 })
  }) },
  { value: 7, label: ctx.$t('Main.TimeMachine.Days.val', {
    p1: ctx.$tc('Main.TimeMachine.Days.p1', 7, { n: 7 })
  }) },
  { value: 30, label: ctx.$t('Main.TimeMachine.Days.val', {
    p1: ctx.$tc('Main.TimeMachine.Days.p1', 30, { n: 30 })
  }) },
  { value: 60, label: ctx.$t('Main.TimeMachine.Days.val', {
    p1: ctx.$tc('Main.TimeMachine.Days.p1', 60, { n: 60 })
  }) },
  { value: 90, label: ctx.$t('Main.TimeMachine.Days.val', {
    p1: ctx.$tc('Main.TimeMachine.Days.p1', 90, { n: 90 })
  }) },
  { value: 180, label: ctx.$t('Main.TimeMachine.Month.val', {
    p1: ctx.$tc('Main.TimeMachine.Month.p1', 6, { n: 6 })
  }) },
  { value: 365, label: ctx.$t('Main.TimeMachine.Years.val', {
    p1: ctx.$tc('Main.TimeMachine.Years.p1', 1, { n: 1 })
  }) }
]);
