<template>
  <SlModal
    :id="id"
    :width="480"
    no-header
    :loading="isLoading"
    @on-enter="handleClose"
    @show="onModalOpen"
    @hide="onModalHide"
  >
    <div class="modal-content system-details">
      <div class="system-details__logo">
        <icon
          data="@icons/logo.svg"
          class="size-logo"
        />
      </div>
      <div v-if="systemVersion">
        <div class="system-details__title heading-4-sb grey-90">
          {{ `Streamline Version ${systemVersion.serverVersion} x64` }}
          <span @click="handleInfoButtonClick">
            <SlInfoButton v-tooltip="getTooltip(commitHash)" />
          </span>
        </div>
        <div class="body-1 grey-70">
          {{ `Copyright © ${systemVersion.copyrightYear} GMDH, Inc.` }}
        </div>
        <div
          v-if="systemVersion.updates"
          class="system-details__btn-wr"
        >
          <SlButton
            v-if="systemVersion.updates.Stable"
            variant="text"
            color="primary"
            :disabled="!isAvailableUpdate"
            @click="handleUpdateSlVersion('Stable')"
          >
            <template #prepend>
              <icon
                data="@sidebar/update.svg"
                class="size-16 fill-off"
              />
            </template>
            {{ $t('Web.UpdateSl.UpdateStableSl', { 1: systemVersion.updates.Stable }) }}
          </SlButton>
          <SlButton
            v-if="systemVersion.updates.Latest"
            variant="text"
            color="grey"
            :disabled="!isAvailableUpdate"
            @click="handleUpdateSlVersion('Latest')"
          >
            <template #prepend>
              <icon
                data="@sidebar/update.svg"
                class="size-16 fill-off"
              />
            </template>
            {{ $t('Web.UpdateSl.UpdateLatestSl', { 1: systemVersion.updates.Latest }) }}
          </SlButton>
        </div>
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          full-width
          @click.stop="handleClose"
        >
          {{ $t('Common.Ok') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { mapActions } from 'vuex';
import { getTooltip } from '@/helpers/shared/tooltip';
import { copyToClipboard } from '@/helpers/utils/clipboard';

export default {
  name: 'SystemDetailsModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      systemVersion: null,
      isLoading: false,
      isAvailableUpdate: true
    };
  },
  computed: {
    commitHash() {
      return this.systemVersion.commitHash?.slice(0, 20);
    }
  },
  methods: {
    getTooltip,
    ...mapActions({
      updateSlVersion: 'project/updateSlVersion'
    }),
    async onModalOpen() {
      if (this.systemVersion) {
        return;
      }

      try {
        this.isLoading = true;

        const response = await this.$store.dispatch('project/getSystemVersion');

        this.systemVersion = response?.data;
      } finally {
        this.isLoading = false;
      }
    },
    onModalHide() {
      this.isLoading = false;
    },
    handleClose() {
      this.hideModal(this.id);
    },
    handleInfoButtonClick() {
      copyToClipboard(this.commitHash);
    },
    handleUpdateSlVersion(version) {
      this.updateSlVersion(version);
      this.isAvailableUpdate = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/system-details-modal.scss";
</style>
