<template>
  <SlDropdown
    theme="sidebar-menu-dropdown"
    :placement="placement"
    :overflow-padding="5"
    auto-hide
    handle-resize
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) in $scopedSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
  </SlDropdown>
</template>

<script>
export default {
  name: 'SidebarDropdown',
  props: {
    direction: {
      type: String,
      default: 'right'
    },
    expanded: Boolean
  },
  computed: {
    placement() {
      return this.expanded ? 'top' : this.direction;
    }
  }
};
</script>