<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
    persistent
    @created="onCreated"
    @hide="onHide"
  >
    <div class="modal-content">
      <SlLoader :text="false" />
      <div class="heading-4-sb grey-90">
        {{ $t('Web.Modals.OAuthLoginTitle', { 1: source }) }}
      </div>
    </div>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'DatasourceConnectionModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.DATASOURCE_CONNECTION,
      source: null
    };
  },
  methods: {
    onHide() {
      this.source = null;
    }
  }
};
</script>
<style lang="scss" scoped>

.modal-content {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
</style>
