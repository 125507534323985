var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"title":_vm.$t('DbImportDialog.Ui.:Caption'),"loading":_vm.isLoading,"close-callback":_vm.handleCancel,"persistent":""},on:{"on-enter":_vm.handleConnect,"created":_vm.onCreated,"show":_vm.onModalShow,"hidden":_vm.onModalHide},scopedSlots:_vm._u([{key:"modals",fn:function(){return [_c('DatasourceConnectionError',{attrs:{"id":_vm.modalsId.CONNECTOR_ERROR}})]},proxy:true},{key:"footer",fn:function(){return [_c('SlModalFooter',{attrs:{"between":""}},[_c('SlLink',{attrs:{"href":_vm.GUIDE_DATABASE_CONNECTION_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/question.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.ButtonHelp'))+" ")]),_c('div',{staticClass:"sl-modal__footer-group"},[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleConnect}},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.ButtonConnect'))+" ")])],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",staticClass:"modal-content database-connector"},[_c('div',{staticClass:"database-connector__row database-connector__row--50"},[_c('SlControl',{attrs:{"options":_vm.connectionTypeOptions,"label":_vm.$t('Web.DbImport.LabelConnectionType')},model:{value:(_vm.connectionTypeModel),callback:function ($$v) {_vm.connectionTypeModel=$$v},expression:"connectionTypeModel"}})],1),(_vm.isSQLConnection)?[_c('div',{staticClass:"database-connector__row"},[_c('SlValidate',{key:"host",attrs:{"vid":"host","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbHost'),"is-invalid":invalid,"required":""},model:{value:(_vm.hostModel),callback:function ($$v) {_vm.hostModel=$$v},expression:"hostModel"}})]}}],null,false,3954377422)}),_c('SlValidate',{key:"port",staticClass:"database-connector__port",attrs:{"vid":"port","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbPort'),"is-invalid":invalid,"type":"number","required":""},model:{value:(_vm.portModel),callback:function ($$v) {_vm.portModel=$$v},expression:"portModel"}})]}}],null,false,2604775491)})],1),_c('div',{staticClass:"database-connector__row"},[_c('SlValidate',{key:"databaseName",attrs:{"vid":"databaseName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbDatabase'),"is-invalid":invalid,"required":""},model:{value:(_vm.databaseNameModel),callback:function ($$v) {_vm.databaseNameModel=$$v},expression:"databaseNameModel"}})]}}],null,false,816191753)})],1)]:[_c('div',{staticClass:"database-connector__row"},[_c('SlValidate',{key:"datasourceName",attrs:{"vid":"datasourceName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.DbImport.ConnectionString'),"placeholder":_vm.dataSourceNamePlaceholder,"is-invalid":invalid,"required":""},model:{value:(_vm.dataSourceNameModel),callback:function ($$v) {_vm.dataSourceNameModel=$$v},expression:"dataSourceNameModel"}})]}}])})],1)],_c('div',{staticClass:"database-connector__row"},[_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbUserName')},model:{value:(_vm.usernameModel),callback:function ($$v) {_vm.usernameModel=$$v},expression:"usernameModel"}})],1),_c('div',{staticClass:"database-connector__row"},[_c('SlPasswordInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbPassword')},model:{value:(_vm.passwordModel),callback:function ($$v) {_vm.passwordModel=$$v},expression:"passwordModel"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }