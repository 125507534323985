<template>
  <SlDropdownOption
    v-bind="$attrs"
    :selected="selected"
    select-close
    @select="handleClick"
  >
    <template
      v-for="(_, slot) in $scopedSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
    <template
      v-if="icon"
      #prepend
    >
      <icon
        class="size-20 fill-off color-grey-50"
        :data="iconData"
      />
    </template>

    {{ label }}

    <template
      v-if="selected"
      #append
    >
      <icon
        class="size-20 color-primary-80 fill-off"
        data="@icons/check.svg"
      />
    </template>
    <template
      v-else-if="$scopedSlots.append"
      #append
    >
      <slot name="append" />
    </template>
  </SlDropdownOption>
</template>

<script>
export default {
  name: 'SidebarDropdownButton',
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    selected: Boolean
  },
  computed: {
    iconData() {
      return require(`@sidebar/${this.icon}.svg`);
    }
  },
  methods: {
    handleClick() {
      // wait to close dropdown
      setTimeout(() => this.$emit('click'), 100);
    }
  }
};
</script>
