<template>
  <SlModal
    :id="id"
    :title="$t('Web.Sidebar.Preferences')"
    @created="onCreated"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <div class="modal-content">
      <div class="language-ui__block">
        <div class="language-ui__block-title">
          {{ $t('Main.Ui.menuLanguage') }}
        </div>
        <LangSwitcher @change-lang="switchLanguage" />
      </div>
      <div class="language-ui__block">
        <div class="language-ui__block-column">
          <span class="language-ui__block-title">
            {{ $t('Settings.Ui.grpAccessibility') }}
          </span>
          <span class="body-1 grey-70">
            {{ $t('Web.Settings.General.accessibilityDescription') }}
          </span>
        </div>
        <div class="language-ui__block-row">
          <SlCheckbox
            v-model="colorblindModel"
            :label="$t('Settings.Ui.chkBlindMode')"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="hideModal(id)"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleSaveSettings">
          {{ $t('Common.Save') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LangSwitcher from '@/components/Settings/LangSwitcher';
import { modal } from '@/mixins/modal';

export default {
  name: 'LanguageUiModal',
  components: {
    LangSwitcher
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      colorblindModel: '',
      languageModel: ''
    };
  },
  computed: {
    ...mapState({
      colorblindMode: state => state.settings.colorblind_mode,
      language: state => state.settings.language
    })
  },
  methods: {
    ...mapActions('settings', [
      'setColorblindMode',
      'setLanguageByCode',
      'saveSettings'
    ]),
    onModalShow() {
      this.colorblindModel = this.colorblindMode;
      this.languageModel = this.language || this.$root.$i18n.locale;
    },
    onModalHide() {
      this.colorblindModel = '';
      this.languageModel = '';
    },
    switchLanguage(val) {
      this.languageModel = val;
    },
    async handleSaveSettings() {
      this.setColorblindMode(this.colorblindModel);
      this.setLanguageByCode(this.languageModel);
      this.hideModal(this.id);

      await this.saveSettings({
        i18n: this.$root.$i18n
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/language-ui-modal";
</style>