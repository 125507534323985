<template>
  <SlModal
    :id="id"
    :title="$t('Main.Ui.acQbImport')"
    :loading="isLoading"
    persistent
    @on-enter="checkConnection"
    @created="onCreated"
    @hidden="onModalHide"
  >
    <template #modals>
      <QBErrorModal :id="modalsId.QB_DESKTOP_ERROR" />
    </template>

    <div class="modal-content body-1 grey-70">
      <SlAlert
        :accent="$t('Web.QB.steps')"
        type="info"
      >
        <template #text>
          <ol>
            <i18n
              path="Web.QB.stepInstall"
              tag="li"
            >
              <template v-slot:1>
                <SlLink
                  color="primary"
                  href="https://gmdhsoftware.com/QBFC13_0Installer.exe"
                  target="_blank"
                >
                  QuickBooks COM API
                </SlLink>
              </template>
            </i18n>
            <li>{{ $t('Web.QB.stepOpen') }}</li>
            <li>{{ $t('Web.QB.stepFile') }}</li>
            <li>{{ $t('Web.QB.stepDialog') }}</li>
          </ol>
        </template>
      </SlAlert>
      <SlInput
        v-model.trim="filepath"
        :placeholder="$t('Web.File.filepathPlaceholder')"
        :label="$t('Web.QB.filepath')"
      />
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="checkConnection">
          {{ $t('Web.DbImport.ButtonConnect') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import { connection } from '@/mixins/connection';
import QBErrorModal from '@/components/Modals/Connections/Connectors/QB/QBErrorModal.vue';
import modalsId from '@/config/shared/modalsId.config';
import { connectionTypes } from '@/config/connection';
import { connectorRouteNames } from '@/config/router/router.config';

export default {
  name: 'QBConnectorModal',
  components: {
    QBErrorModal
  },
  mixins: [modal, connection],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      type: connectionTypes.QB_DESKTOP,
      isNew: false,
      isLoading: false
    };
  },
  computed: {
    filepath: {
      get() {
        return this.$sl_isEditingRoute
          ? this.connectionData.optional?.filepath
            || this.connectionData.optional?.manual_filepath
          : this.connectionData.optional?.manual_filepath;
      },
      set(value) {
        const parsedFilepath = value.replace(/"/g, '');
        const key = this.connectionData.optional?.filepath ? 'filepath' : 'manual_filepath';

        this.setOptionalValue({
          key,
          value: parsedFilepath
        });
      }
    }
  },
  methods: {
    ...mapActions({
      checkQbConnection: 'qb/checkQbConnection',
      logout: 'user/logout'
    }),
    onModalHide() {
      this.isNew = false;
      this.isLoading = false;
    },
    goToConnectorPage() {
      if (this.isReconnect) {
        return this.hideModal(this.id);
      }

      if (this.isNew) {
        return this.$router.push({
          name: connectorRouteNames.CREATE_PROJECT_CONNECTOR,
          params: {
            connector: this.type
          }
        });
      }

      return this.$router.push({
        name: connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
        params: {
          connector: this.type
        }
      });
    },
    async checkConnection() {
      this.isLoading = true;

      this.checkQbConnection({
        path: this.filepath,
        ignoreResult: !this.isNew,
        beforeStart: () => this.showModal(modalsId.QB_DESKTOP_LOADER),
        failed: (text, title) => this.showModal(modalsId.QB_DESKTOP_ERROR, { text, title }),
        finished: this.goToConnectorPage,
        finally: () => setTimeout(() => {
          this.isLoading = false;
          this.hideModal(modalsId.QB_DESKTOP_LOADER);
        }, 200)
      });
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";

.modal-content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

ol {
  padding-left: 12px !important;
  list-style-type: decimal;
}

.qb-loader {
  max-width: 420px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 24px;
  border-radius: 8px;

  background-color: $white;

  .body-1 {
    text-align: center;
  }
}
</style>
