<template>
  <SlModal
    :id="id"
    :title="$t('DbImportDialog.Ui.:Caption')"
    :loading="isLoading"
    :close-callback="handleCancel"
    persistent
    @on-enter="handleConnect"
    @created="onCreated"
    @show="onModalShow"
    @hidden="onModalHide"
  >
    <template #modals>
      <DatasourceConnectionError :id="modalsId.CONNECTOR_ERROR" />
    </template>

    <ValidationObserver
      ref="observer"
      class="modal-content database-connector"
    >
      <div class="database-connector__row database-connector__row--50">
        <SlControl
          v-model="connectionTypeModel"
          :options="connectionTypeOptions"
          :label="$t('Web.DbImport.LabelConnectionType')"
        />
      </div>
      <template v-if="isSQLConnection">
        <div class="database-connector__row">
          <SlValidate
            v-slot="{ invalid }"
            key="host"
            vid="host"
            rules="required"
          >
            <SlInput
              v-model="hostModel"
              :label="$t('DbInsideSourceWidget.Ui.lbHost')"
              :is-invalid="invalid"
              required
            />
          </SlValidate>
          <SlValidate
            v-slot="{ invalid }"
            key="port"
            vid="port"
            rules="required"
            class="database-connector__port"
          >
            <SlInput
              v-model="portModel"
              :label="$t('DbInsideSourceWidget.Ui.lbPort')"
              :is-invalid="invalid"
              type="number"
              required
            />
          </SlValidate>
        </div>
        <div class="database-connector__row">
          <SlValidate
            v-slot="{ invalid }"
            key="databaseName"
            vid="databaseName"
            rules="required"
          >
            <SlInput
              v-model="databaseNameModel"
              :label="$t('DbInsideSourceWidget.Ui.lbDatabase')"
              :is-invalid="invalid"
              required
            />
          </SlValidate>
        </div>
      </template>
      <template v-else>
        <div class="database-connector__row">
          <SlValidate
            v-slot="{ invalid }"
            key="datasourceName"
            vid="datasourceName"
            rules="required"
          >
            <SlInput
              v-model="dataSourceNameModel"
              :label="$t('Web.DbImport.ConnectionString')"
              :placeholder="dataSourceNamePlaceholder"
              :is-invalid="invalid"
              required
            />
          </SlValidate>
        </div>
      </template>
      <div class="database-connector__row">
        <SlInput
          v-model="usernameModel"
          :label="$t('DbImportDialog.Ui.lbUserName')"
        />
      </div>
      <div class="database-connector__row">
        <SlPasswordInput
          v-model="passwordModel"
          :label="$t('DbImportDialog.Ui.lbPassword')"
        />
      </div>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter between>
        <SlLink
          :href="GUIDE_DATABASE_CONNECTION_URL"
          target="_blank"
        >
          <template #prepend>
            <icon
              data="@icons/question.svg"
              class="fill-off size-16"
            />
          </template>
          {{ $t('Web.DbImport.ButtonHelp') }}
        </SlLink>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton @click="handleConnect">
            {{ $t('Web.DbImport.ButtonConnect') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import DatasourceConnectionError from '@/components/Modals/Connections/Connectors/Shared/DatasourceConnectionError';
import { GUIDE_DATABASE_CONNECTION_URL } from '@/config/shared/resources.config';
import { dataFields } from '@/config/connection/dbRelated.config';
import {
  connectionData,
  connectionTypeOptions,
  dbConnectionTypes
} from '@/config/connection/database.config';
import modalsId from '@/config/shared/modalsId.config';
import { connectorRouteNames } from '@/config/router/router.config';

export default {
  name: 'DatabaseConnectorModal',
  components: {
    DatasourceConnectionError
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      GUIDE_DATABASE_CONNECTION_URL,
      connectionTypeOptions,
      isNew: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      selectedConnector: state => state.connection.selectedConnector,
      [dataFields.CONNECTION_DATA]: state => state.dbRelated[dataFields.CONNECTION_DATA],
      [dataFields.CONNECTION_DATA_CACHE]: state => state.dbRelated[dataFields.CONNECTION_DATA_CACHE]
    }),
    isSQLConnection() {
      return this.connectionTypeModel === dbConnectionTypes.MYSQL;
    },
    connectionDataKey() {
      return this.isNew
        ? dataFields.CONNECTION_DATA
        : dataFields.CONNECTION_DATA_CACHE;
    },
    connectionTypeModel: {
      get() {
        return this[this.connectionDataKey]?.type;
      },
      set(value) {
        this.setCurrentData({ key: 'type', value });
      }
    },
    dataSourceNameModel: {
      get() {
        return this[this.connectionDataKey]?.sourceName;
      },
      set(value) {
        this.setCurrentData({ key: 'sourceName', value });
      }
    },
    databaseNameModel: {
      get() {
        return this[this.connectionDataKey]?.database;
      },
      set(value) {
        this.setCurrentData({ key: 'database', value });
      }
    },
    hostModel: {
      get() {
        return this[this.connectionDataKey]?.host;
      },
      set(value) {
        this.setCurrentData({ key: 'host', value });
      }
    },
    portModel: {
      get() {
        return this[this.connectionDataKey]?.port;
      },
      set(value) {
        this.setCurrentData({ key: 'port', value });
      }
    },
    usernameModel: {
      get() {
        return this[this.connectionDataKey]?.username;
      },
      set(value) {
        this.setCurrentData({ key: 'username', value });
      }
    },
    passwordModel: {
      get() {
        return this[this.connectionDataKey]?.password;
      },
      set(value) {
        this.setCurrentData({ key: 'password', value });
      }
    },
    dataSourceNamePlaceholder() {
      return this.connectionTypeModel === dbConnectionTypes.BIG_QUERY
        ? ''
        : this.$t('Web.DbImport.PlaceholderDatasourceName');
    }
  },
  methods: {
    ...mapActions({
      updateLocalConnectionData: 'dbRelated/updateLocalConnectionData',
      resetState: 'dbRelated/resetState',
      testConnection: 'dbRelated/testConnection',
      setConnectionData: 'dbRelated/setConnectionData'
    }),
    onModalShow() {
      if (!this.isNew || (this.$sl_isEditingRoute && !this.$route.params.type)) {
        return this.setConnectionData({
          field: dataFields.CONNECTION_DATA_CACHE,
          value: this[dataFields.CONNECTION_DATA]
        });
      }

      this.setConnectionData({
        field: dataFields.CONNECTION_DATA,
        value: connectionData()
      });
    },
    onModalHide() {
      this.setConnectionData({
        field: this.isNew
          ? dataFields.CONNECTION_DATA
          : dataFields.CONNECTION_DATA_CACHE,
        value: {}
      });

      this.isNew = false;
      this.isLoading = false;
    },
    setCurrentData(payload) {
      this.updateLocalConnectionData({
        ...payload,
        dataKey: this.connectionDataKey
      });
    },
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      try {
        this.isLoading = true;

        await this.testConnection({
          connectionDataKey: this.connectionDataKey
        });

        if (this.isNew) {
          const resetValues = this.connectionTypeModel === dbConnectionTypes.MYSQL
            ? { sourceName: '' }
            : { port: '', host: '', database: '' };

          await this.setConnectionData({
            field: dataFields.CONNECTION_DATA,
            value: {
              ...this[this.connectionDataKey],
              ...resetValues
            }
          });

          await this.setConnectionData({
            field: dataFields.CONNECTION_DATA_CACHE,
            value: {}
          });

          return this.$router.push({
            name: this.$sl_isEditingRoute
              ? connectorRouteNames.EDIT_CONNECTION_CONNECTOR
              : connectorRouteNames.CREATE_PROJECT_CONNECTOR,
            params: {
              connector: this.selectedConnector
            }
          });
        }

        await this.setConnectionData({
          field: dataFields.CONNECTION_DATA,
          value: this[dataFields.CONNECTION_DATA_CACHE]
        });

        this.hideModal(this.id);
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          connector: this.$t('Web.Modals.Error.TextConnectToDatasource'),
          error: e?.message
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.hideModal(this.id);

      if (this.isNew) {
        this.resetState();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection/connectors/database/database-connector-modal.scss";
</style>
