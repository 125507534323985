<template>
  <SlModal
    :id="id"
    :title="$t('Main.Ui.acImportFromSpire')"
    :loading="isLoading"
    :close-callback="handleCancel"
    persistent
    @on-enter="handleConnect"
    @created="onCreated"
    @hide="onModalHide"
  >
    <template #modals>
      <DatasourceConnectionError :id="modalsId.CONNECTOR_ERROR" />
    </template>

    <ValidationObserver
      ref="observer"
      class="modal-content dynamic-nav-connector"
    >
      <div class="dynamic-nav-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="domain"
          rules="required"
        >
          <SlInput
            v-model="domainModel"
            :label="$t('Web.BaseConnectors.Form.Host')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
        <SlValidate
          v-slot="{ invalid }"
          vid="port"
          rules="required"
        >
          <SlInput
            v-model="portModel"
            :label="$t('Web.BaseConnectors.Form.Port')"
            type="number"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div class="dynamic-nav-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="login"
          rules="required"
        >
          <SlInput
            v-model="loginModel"
            :label="$t('Web.BaseConnectors.Form.Login')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div class="dynamic-nav-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="password"
          rules="required"
        >
          <SlPasswordInput
            v-model="passwordModel"
            :label="$t('Web.BaseConnectors.Form.Password')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div
        v-if="showDropzoneFile"
        class="connector-content-row"
      >
        <SlDropzonePreviewItem
          :file="dropzoneFile"
          @remove="handleRemoveFile"
        />
      </div>
      <SlValidate
        v-else
        v-slot="{ invalid, validate }"
        vid="file"
        rules="sl_file_type:.pem|sl_file_size"
      >
        <SlDropzone
          ref="dropzone"
          :label="$t('Web.BaseConnectors.Form.TLSCert')"
          :description="$t('Web.BaseConnectors.Form.TLSCertDescription')"
          :accepted="pemAccepted"
          :upload-callback="({ value }) => uploadFile(value)"
          :validator="validate"
          :is-invalid="invalid"
          @upload-start="handleToggleUploading(true)"
          @upload-end="handleToggleUploading(false)"
          @files-updated="handleFilesUpdated"
        >
          <template #accepted>
            {{ $t('Web.File.PEMFormat') }}
          </template>
        </SlDropzone>
      </SlValidate>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleConnect">
          {{ $t('Web.DbImport.ButtonConnect') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { connection } from '@/mixins/connection';
import DatasourceConnectionError from '@/components/Modals/Connections/Connectors/Shared/DatasourceConnectionError';
import modalsId from '@/config/shared/modalsId.config';
import { connectorRouteNames } from '@/config/router/router.config';
import { connectionTypes } from '@/config/connection';
import { pemAccepted } from '@/config/utils/fileUpload.config';

export default {
  name: 'SpireConnectorModal',
  components: {
    DatasourceConnectionError
  },
  mixins: [modal, connection],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      pemAccepted,
      isNew: false,
      isLoading: false,
      isUploading: false,
      type: connectionTypes.SPIRE
    };
  },
  computed: {
    ...mapState({
      selectedConnector: state => state.connection.selectedConnector
    }),
    fileName() {
      return this.connectionData.optional?.fileName;
    },
    certFile: {
      get() {
        return this.connectionData.optional?.file;
      },
      set(value) {
        this.setOptionalValue({
          key: 'file',
          value
        });
      }
    },
    showDropzoneFile() {
      return this.certFile || this.fileName;
    },
    dropzoneFile() {
      return this.certFile || {
        name: this.fileName
      };
    },
    domainModel: {
      get() {
        return this.connectionData.required.domain;
      },
      set(value) {
        this.setRequiredValue({
          key: 'domain',
          value
        });
      }
    },
    portModel: {
      get() {
        return this.connectionData.required.port;
      },
      set(value) {
        this.setRequiredValue({
          key: 'port',
          value
        });
      }
    },
    loginModel: {
      get() {
        return this.connectionData.required.login;
      },
      set(value) {
        this.setRequiredValue({
          key: 'login',
          value
        });
      }
    },
    passwordModel: {
      get() {
        return this.connectionData.required.password;
      },
      set(value) {
        this.setRequiredValue({
          key: 'password',
          value
        });
      }
    }
  },
  methods: {
    ...mapActions({
      uploadFile: 'connection/uploadFile',
      getSpireDatabases: 'spire/getSpireDatabases'
    }),
    handleRemoveFile() {
      this.setOptionalValue({
        key: 'file',
        value: null
      });

      this.setOptionalValue({
        key: 'fileName',
        value: ''
      });

      this.setOptionalValue({
        key: 'fileId',
        value: undefined
      });

      this.setOptionalValue({
        key: 'fileRemoved',
        value: true
      });
    },
    onCreated(props) {
      Object.keys(props).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(props, key)) {
          this[key] = props[key];
        }
      });
    },
    onModalHide() {
      this.isNew = false;
      this.isLoading = false;
    },
    handleToggleUploading(isUploading) {
      this.isUploading = isUploading;
    },
    async handleFilesUpdated(files) {
      this.certFile = files[0];
    },
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      try {
        this.isLoading = true;

        const connected = await this.getSpireDatabases();

        if (!connected) {
          return this.showModal(modalsId.CONNECTOR_ERROR, {
            connector: this.$t('Web.Modals.Error.TextConnectToDatasource')
          });
        }

        if (this.isNew) {
          this.$router.push({
            name: this.$sl_isEditingRoute
              ? connectorRouteNames.EDIT_CONNECTION_CONNECTOR
              : connectorRouteNames.CREATE_PROJECT_CONNECTOR,
            params: {
              connector: this.selectedConnector
            }
          });
        }

        this.hideModal(this.id);
      } catch {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          connector: this.$t('Web.Modals.Error.TextConnectToDatasource')
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.dynamic-nav-connector {
  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;

    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
