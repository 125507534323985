var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"title":_vm.$t('Main.Ui.acImportFromOdoo'),"loading":_vm.isLoading,"close-callback":_vm.handleCancel},on:{"on-enter":_vm.handleConnect,"created":_vm.onCreated,"show":_vm.onModalShow,"hidden":_vm.onModalHide},scopedSlots:_vm._u([{key:"modals",fn:function(){return [_c('DatasourceConnectionError',{attrs:{"id":_vm.modalsId.CONNECTOR_ERROR}})]},proxy:true},{key:"footer",fn:function(){return [_c('SlModalFooter',{attrs:{"between":""}},[_c('SlLink',{attrs:{"href":_vm.GUIDE_ODOO_CONNECTION_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-20",attrs:{"data":require("@icons/question.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.Netsuite.LinkDocumentation'))+" ")]),_c('div',{staticClass:"sl-modal__footer-group"},[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleConnect}},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.ButtonConnect'))+" ")])],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",staticClass:"modal-content odoo-connector"},[_c('div',{staticClass:"odoo-connector__row"},[_c('SlValidate',{key:"host",attrs:{"vid":"host","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbHost'),"is-invalid":invalid,"required":""},model:{value:(_vm.hostModel),callback:function ($$v) {_vm.hostModel=$$v},expression:"hostModel"}})]}}])}),_c('SlValidate',{staticClass:"odoo-connector__port",attrs:{"vid":"port","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbPort'),"is-invalid":invalid,"required":""},model:{value:(_vm.portModel),callback:function ($$v) {_vm.portModel=$$v},expression:"portModel"}})]}}])})],1),_c('div',{staticClass:"odoo-connector__row"},[_c('SlValidate',{attrs:{"vid":"databaseName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Ui.lbDatabase'),"is-invalid":invalid,"required":""},model:{value:(_vm.databaseNameModel),callback:function ($$v) {_vm.databaseNameModel=$$v},expression:"databaseNameModel"}})]}}])})],1),_c('div',{staticClass:"odoo-connector__row"},[_c('SlInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Labels.lbDbUsername')},model:{value:(_vm.usernameModel),callback:function ($$v) {_vm.usernameModel=$$v},expression:"usernameModel"}}),_c('SlPasswordInput',{attrs:{"label":_vm.$t('DbInsideSourceWidget.Labels.lbDbPassword')},model:{value:(_vm.passwordModel),callback:function ($$v) {_vm.passwordModel=$$v},expression:"passwordModel"}})],1),_c('div',{staticClass:"odoo-connector__row"},[_c('SlValidate',{attrs:{"vid":"odooUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideImport.Names.OdooServerUrl'),"is-invalid":invalid,"required":""},model:{value:(_vm.odooUrlModel),callback:function ($$v) {_vm.odooUrlModel=$$v},expression:"odooUrlModel"}})]}}])})],1),_c('div',{staticClass:"odoo-connector__row"},[_c('SlValidate',{attrs:{"vid":"odooUsername","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbInsideImport.Names.lbAPIUsername', { 1: 'Odoo' }),"is-invalid":invalid,"required":""},model:{value:(_vm.odooUsernameModel),callback:function ($$v) {_vm.odooUsernameModel=$$v},expression:"odooUsernameModel"}})]}}])}),_c('SlValidate',{attrs:{"vid":"odooPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('DbInsideImport.Names.lbAPIPassword', { 1: 'Odoo' }),"is-invalid":invalid,"required":""},model:{value:(_vm.odooPasswordModel),callback:function ($$v) {_vm.odooPasswordModel=$$v},expression:"odooPasswordModel"}})]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }