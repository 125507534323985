<template>
  <SidebarDropdown v-bind="$attrs">
    <template
      v-for="(_, slot) in $scopedSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
    <template #content>
      <div class="dropdown-menu">
        <div class="dropdown-menu__block dropdown-menu__block--info">
          <div
            v-tooltip.top="lastUpdateTooltip"
            class="dropdown-menu__title"
          >
            <div
              class="dropdown-menu__title-dot"
              :class="updateDataStatusClass"
            />
            {{ lastUpdate }}
          </div>
        </div>
        <div
          v-if="$sl_hasAccess($sl_features.updatePrj)"
          class="dropdown-menu__block"
        >
          <SidebarDropdownButton
            :label="$t('Main.Ui.acReloadLatestPeriods')"
            icon="update"
            @click="handleReImport(updateData.QUICK, logLevel.OFF)"
          />
          <SidebarDropdownButton
            :label="$t('Main.ReloadFully')"
            icon="update"
            @click="handleReImport(updateData.FULL, logLevel.OFF)"
          />
        </div>
        <div class="dropdown-menu__block dropdown-menu__block--info">
          <div class="dropdown-menu__description">
            {{ $t('Web.UpdateData.LastTxDate', { 1: dataAsOf }) }}
          </div>
          <div class="dropdown-menu__description">
            {{ $t('Web.UpdateData.SimStartDate', { 1: calculationDate }) }}
          </div>
        </div>
      </div>
    </template>
  </SidebarDropdown>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { access } from '@/mixins/access';
import SidebarDropdown from '@/components/SidebarMenu/Dropdown/SidebarDropdown.vue';
import SidebarDropdownButton from '@/components/SidebarMenu/Dropdown/SidebarDropdownButton.vue';
import { updateData, logLevel } from '@/config/project/updateData.config';

export default {
  name: 'UpdateDropdown',
  components: {
    SidebarDropdownButton,
    SidebarDropdown
  },
  mixins: [access],
  data() {
    return {
      maxLastUpdateLength: 30,
      updateData,
      logLevel
    };
  },
  inject: ['handleReImport'],
  computed: {
    ...mapState({
      updateFailed: state => state.project.project.updateFailed
    }),
    ...mapGetters({
      lastUpdate: 'project/lastUpdate',
      dataAsOf: 'project/dataAsOf',
      calculationDate: 'project/calculationDate'
    }),
    lastUpdateTooltip() {
      return this.lastUpdate.length >= this.maxLastUpdateLength && this.lastUpdate;
    },
    updateDataStatusClass() {
      return this.updateFailed ? 'dropdown-menu__title-dot--red' : 'dropdown-menu__title-dot--green';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/sidebar-menu/dropdown-menu.scss";
</style>
