<template>
  <SlModal
    :id="id"
    :title="$t('Web.ShortcutsModal.Title')"
    @created="onCreated"
  >
    <div class="modal-content">
      <div
        v-for="({ title, values }, blockIndex) in shortcuts"
        :key="blockIndex"
        class="keyboard-shortcuts__block"
      >
        <div class="keyboard-shortcuts__block-title">
          {{ title }}
        </div>
        <div
          v-for="(item, valueIndex) in values"
          :key="valueIndex"
          class="keyboard-shortcuts__row"
        >
          <span class="keyboard-shortcuts__row-title">
            {{ item.title }}
            <SlInfoButton
              v-if="item.info"
              v-tooltip="item.info"
            />
          </span>
          <div class="keyboard-shortcuts__row-shortcut">
            <SlShortcut
              v-for="(label, shortcutIndex) in item.shortcut"
              :key="shortcutIndex"
              :value="label"
              outline
            />
          </div>
        </div>
      </div>
    </div>
  </SlModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { bomShortcuts, shortcutsConfig } from '@/config/shortcuts/shortcuts.config';
import cloneObject from '@/helpers/utils/cloneObject';

export default {
  name: 'KeyboardShortcutsModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      hasBom: 'project/hasBom'
    }),
    shortcuts() {
      return Object.values(shortcutsConfig(this)).map(group => ({
        ...group,
        values: this.filterShortcuts(group.values)
      }));
    }
  },
  methods: {
    filterShortcuts(shortcuts) {
      const filteredShortcuts = cloneObject(shortcuts);

      if (!this.hasBom) {
        bomShortcuts.forEach(shortcut => delete filteredShortcuts[shortcut]);
      }

      return filteredShortcuts;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/keyboard-shortcuts-modal";
</style>