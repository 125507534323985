var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SidebarDropdown',_vm._b({on:{"hide":_vm.resetExpand,"toggle":_vm.handleToggle},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope || {})]}}}),{key:"content",fn:function(){return [_c('SlOverlay',{attrs:{"show":_vm.isLoading}},[_c('SlLoader',{attrs:{"text":false,"size":"xs"}})],1),_c('div',{staticClass:"dropdown-menu"},[(_vm.runningPermanentProjects.length)?_c('div',{staticClass:"dropdown-menu__block",class:{
          'dropdown-menu__block--scroll': _vm.showMorePermanentVisible
        }},[_c('div',{staticClass:"dropdown-menu__title dropdown-menu__title--sm"},[_vm._v(" "+_vm._s(_vm.$t('Web.Sidebar.RunningProjects'))+" ")]),_vm._l((_vm.runningPermanentProjects),function(project){return _c('SidebarDropdownButton',{key:project.id,attrs:{"label":project.name,"selected":project.id === _vm.projectId},on:{"click":function($event){return _vm.handleSwitchProject(project)}}})}),(_vm.showMorePermanentVisible)?_c('div',{staticClass:"dropdown-menu__title dropdown-menu__title--center dropdown-menu__title--sticky",on:{"click":_vm.toggleShowPermanent}},[_vm._v(" "+_vm._s(_vm.showMoreLessTitle[_vm.morePermanent])+" "),_c('icon',{staticClass:"size-16",class:{
              'rotate-180': _vm.morePermanent
            },attrs:{"data":require("@icon/arrow-down.svg")}})],1):_vm._e()],2):_vm._e(),(_vm.runningTemporaryProjects.length)?_c('div',{staticClass:"dropdown-menu__block",class:{
          'dropdown-menu__block--scroll': _vm.showMoreTemporaryVisible
        }},[_c('div',{staticClass:"dropdown-menu__title dropdown-menu__title--sm"},[_vm._v(" "+_vm._s(_vm.$t('Web.Project.Label.Temp'))+" ")]),_vm._l((_vm.runningTemporaryProjects),function(project){return _c('SidebarDropdownButton',{key:project.id,attrs:{"label":project.name,"selected":project.id === _vm.projectId},on:{"click":function($event){return _vm.handleSwitchProject(project)}}})}),(_vm.showMoreTemporaryVisible)?_c('div',{staticClass:"dropdown-menu__title dropdown-menu__title--center dropdown-menu__title--sticky",on:{"click":_vm.toggleShowTemporary}},[_vm._v(" "+_vm._s(_vm.showMoreLessTitle[_vm.moreTemporary])+" "),_c('icon',{staticClass:"size-16",class:{
              'rotate-180': _vm.moreTemporary
            },attrs:{"data":require("@icon/arrow-down.svg")}})],1):_vm._e()],2):_vm._e(),_c('div',{staticClass:"dropdown-menu__block"},[_c('SidebarDropdownButton',{attrs:{"label":_vm.$t('Web.Project.Label.ViewAll'),"icon":"project-switch"},on:{"click":_vm.goToProjectsList}}),(_vm.$sl_isUserAdmin)?_c('SidebarDropdownButton',{attrs:{"label":_vm.$t('Web.Project.Label.Create'),"icon":"plus"},on:{"click":function($event){return _vm.handleCreateProject('toolbar')}},scopedSlots:_vm._u([(_vm.isRestrictedFromAddingProject)?{key:"append-inner",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/upgrade-plan.svg")}})]},proxy:true}:null],null,true)}):_vm._e()],1)])]},proxy:true}],null,true)},'SidebarDropdown',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }