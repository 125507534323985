<template>
  <SlModal
    :id="id"
    :loading="loading"
    :title="modalContent.title"
    @on-enter="handleConfirm"
    @created="onCreated"
    @hidden="setDefaultValues"
    @hide="onHide"
  >
    <ValidationObserver
      ref="observer"
      class="modal-content report-issue"
    >
      <SlValidate
        v-if="needLogs"
        v-slot="{ invalid }"
        key="agreeSend"
        vid="agreeSend"
        :rules="{
          required: {
            message: $t('Web.ReportIssue.AgreeSendValidation')
          }
        }"
      >
        <SlCheckbox
          v-model="agreeSend"
          :not-selected="null"
          :label="$t('Web.ReportIssue.AgreeSend')"
          :is-invalid="invalid"
        />
      </SlValidate>
      <SlValidate
        v-slot="{ invalid }"
        key="username"
        vid="username"
        rules="required"
      >
        <SlInput
          v-model="username"
          :label="$t('Web.ReportIssue.Name')"
          required
          :is-invalid="invalid"
        />
      </SlValidate>
      <SlValidate
        v-slot="{ invalid, validate }"
        key="port"
        vid="port"
        rules="required|email"
        class="database-connector__port"
        immediate
      >
        <SlInput
          v-model="userEmail"
          :label="$t('Web.ReportIssue.Email')"
          required
          :is-invalid="invalid"
          :validator="validate"
        />
      </SlValidate>
      <SlValidate
        v-slot="{ invalid }"
        key="userIssue"
        vid="userIssue"
        rules="required"
        class="database-connector__port"
      >
        <SlTextArea
          v-model="userIssue"
          :is-invalid="invalid"
          :label="modalContent.description"
          :maxlength="9999"
          height="96"
          required
          bottom-round
          autofocus
        />
      </SlValidate>
      <SlValidate
        v-slot="{ invalid, validate }"
        vid="file"
        rules="sl_file_size:20"
      >
        <div class="body-2-md grey-80 mb-4">
          {{ $t('Web.ReportIssue.Attachment') }}
        </div>
        <SlDropzone
          ref="dropzone"
          :exist-file-names="uploadedFileNames"
          accepted="*/*"
          :max-files="MAX_FILES"
          :tooltip-max-files="MAX_FILES"
          :upload-callback="uploadFile"
          :validator="validate"
          :is-invalid="invalid"
          @queue-upload-start="setLoading(true)"
          @queue-upload-end="setLoading(false)"
          @file-removed="handleRemoveFile"
        />
      </SlValidate>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleConfirm">
          {{ $t('Web.ReportIssue.Send') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { addCodeToMessage } from '@/api/helpers/errorRegistry';

export default {
  name: 'ReportIssueModal',
  mixins: [modal, access],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      MAX_FILES: 5,
      name: this.$sl_userName,
      email: this.$sl_userEmail,
      issue: null,
      needLogs: false,
      agreeSend: true,
      loading: false,
      files: []
    };
  },
  computed: {
    uploadedFileNames() {
      return this.files.map(file => file.name);
    },
    username: {
      get() {
        return this.name;
      },
      set(value) {
        this.name = value;
      }
    },
    userEmail: {
      get() {
        return this.email;
      },
      set(value) {
        this.email = value;
      }
    },
    userIssue: {
      get() {
        return this.issue;
      },
      set(value) {
        this.issue = value;
      }
    },
    modalContent() {
      return {
        title: this.needLogs ? this.$t('Web.ReportIssue.Title') : this.$t('Web.Sidebar.SupportEmail'),
        description: this.needLogs ? this.$t('Web.ReportIssue.Description') : this.$t('Web.ReportIssue.DescriptionHowHelp')
      };
    }
  },
  mounted() {
    this.setDefaultValues();
  },
  methods: {
    ...mapActions({
      reportIssue: 'user/reportIssue'
    }),
    setDefaultValues() {
      this.name = this.$sl_userName;
      this.email = this.$sl_userEmail;
      this.issue = null;
      this.agreeSend = null;
      this.files = [];
    },
    handleCancel() {
      this.setDefaultValues();

      this.hideModal(this.id);
    },
    onHide() {
      this.needLogs = false;
    },
    async handleConfirm() {
      const valid = await this.$refs.observer.validate();

      if (!valid || this.loading) {
        return;
      }

      try {
        this.loading = true;

        await this.reportIssue({
          username: this.username,
          email: this.email,
          userIssue: this.userIssue,
          files: this.files,
          needLogs: this.needLogs
        });

        this.handleCancel();

        this.$notify({
          type: 'success',
          title: this.needLogs ? this.$t('Web.ReportIssue.SuccessfulNotifyTitle') : '',
          text: this.$t('Web.ReportIssue.SuccessfulNotifyText')
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          text: e?.message || addCodeToMessage(this.$t('User.Error.ServerErrorText'), e?.code)
        });
      } finally {
        this.loading = false;
      }
    },
    uploadFile(file) {
      this.files.push(file.value);

      return true;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    handleRemoveFile(file) {
      this.files = this.files.filter(item => item.upload.uuid !== file.upload.uuid);
    }
  }
};
</script>

<style lang="scss" scoped>
  .report-issue {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .mb-4 {
      margin-bottom: 4px;
    }
  }
</style>
