var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"width":800,"title":_vm.$t('Main.menuImportSpreadsheet'),"subtitle":_vm.$t('Web.Spreadsheet.ModalConnectionSubtitle'),"close-callback":_vm.handleCancel,"persistent":""},on:{"on-enter":_vm.handleConfirm,"created":_vm.onCreated,"show":_vm.onModalShow,"hidden":_vm.onModalHide},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('SlModalFooter',{attrs:{"between":""}},[_c('div',{staticClass:"sl-modal__footer-group"},[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{attrs:{"disabled":_vm.isUploading},on:{"click":_vm.handleConfirm}},[_vm._v(" "+_vm._s(_vm.$t('Web.Modals.BtnConfirm'))+" ")])],1)])]},proxy:true}])},[_c('div',{staticClass:"modal-content spreadsheet-connector"},[_c('div',{staticClass:"spreadsheet-connector__type-picker"},[_c('SlRadioPanel',{attrs:{"label":_vm.$t('Main.Ui.acOrderListImport'),"description":_vm.$t('Web.Spreadsheet.RadioDescriptionTransactional'),"selected":_vm.connectionTypes.SPREADSHEET_TRANSACTIONAL},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('SlAccordion',{attrs:{"variant":"light","inline":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('SlButton',{attrs:{"variant":"text","color":"grey"},on:{"click":function($event){$event.stopPropagation();return toggle($event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",class:{
                      'rotate--90': !isOpen
                    },attrs:{"data":require("@icons/triangle_down.svg")}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('Web.Spreadsheet.BtnMoreInfo'))+" ")])]}}])},[_c('div',{staticClass:"more-info-links"},[_c('SlLink',{attrs:{"href":_vm.SPREADSHEET_TRANSACTIONAL_TUTORIAL_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/open_link.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.Spreadsheet.BtnInstruction'))+" ")]),_c('SlLink',{attrs:{"href":_vm.SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/download.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.Spreadsheet.BtnDataTemplate'))+" ")])],1)])]},proxy:true}]),model:{value:(_vm.dataModel),callback:function ($$v) {_vm.dataModel=$$v},expression:"dataModel"}}),_c('SlRadioPanel',{attrs:{"label":_vm.$t('Main.Ui.acSheetImport'),"description":_vm.$t('Web.Spreadsheet.RadioDescriptionAggregated'),"selected":_vm.connectionTypes.SPREADSHEET_AGGREGATED},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('SlAccordion',{attrs:{"variant":"light","inline":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                    var isOpen = ref.isOpen;
                    var toggle = ref.toggle;
return [_c('SlButton',{attrs:{"variant":"text","color":"grey"},on:{"click":function($event){$event.stopPropagation();return toggle($event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",class:{
                      'rotate--90': !isOpen
                    },attrs:{"data":require("@icons/triangle_down.svg")}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('Web.Spreadsheet.BtnMoreInfo'))+" ")])]}}])},[_c('div',{staticClass:"more-info-links"},[_c('SlLink',{attrs:{"href":_vm.SPREADSHEET_AGGREGATED_TUTORIAL_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/open_link.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.Spreadsheet.BtnInstruction'))+" ")]),_c('SlLink',{attrs:{"href":_vm.SPREADSHEET_AGGREGATED_TEMPLATE_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/download.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.Spreadsheet.BtnDataTemplate'))+" ")])],1)])]},proxy:true}]),model:{value:(_vm.dataModel),callback:function ($$v) {_vm.dataModel=$$v},expression:"dataModel"}})],1),_c('div',{staticClass:"spreadsheet-connector__upload-label"},[_c('div',{staticClass:"spreadsheet-connector__upload-label-title"},[_vm._v(" "+_vm._s(_vm.dropzoneLabel)+" ")]),_c('div',{staticClass:"spreadsheet-connector__upload-label-description"},[_vm._v(" "+_vm._s(_vm.$t('Web.Spreadsheet.DescriptionMaxFiles', { 1: _vm.MAX_FILES }))+" ")])]),_c('FilesUpload',{ref:"fileUpload",attrs:{"link-alert":_vm.noUploadedLinks},on:{"link-upload-success":function($event){_vm.noUploadedLinks = false},"loading":_vm.toggleUploading}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }