<template>
  <SlModal
    :id="id"
    :title="$t('Web.Netsuite.ModalTitle')"
    :loading="isLoading"
    persistent
    @on-enter="handleConnect"
    @created="onCreated"
    @show="onModalShow"
    @hidden="onModalHide"
  >
    <template #modals>
      <DatasourceConnectionError :id="modalsId.CONNECTOR_ERROR" />
    </template>

    <ValidationObserver
      ref="observer"
      class="modal-content netsuite-connector"
    >
      <div class="netsuite-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="company"
          rules="required"
          mode="eager"
        >
          <SlInput
            v-model="companyModel"
            :label="$t('Web.Netsuite.LabelAccountId')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div class="netsuite-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="consumerKey"
          rules="required"
          mode="eager"
        >
          <SlInput
            v-model="consumerKeyModel"
            :label="$t('Web.Netsuite.LabelConsumerKey')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
        <SlValidate
          v-slot="{ invalid }"
          vid="consumerSecret"
          rules="required"
          mode="eager"
        >
          <SlInput
            v-model="consumerSecretKeyModel"
            :label="$t('Web.Netsuite.LabelConsumerSecret')"
            type="password"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div class="netsuite-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="token"
          rules="required"
          mode="eager"
        >
          <SlInput
            v-model="tokenModel"
            :label="$t('Web.Netsuite.LabelTokenID')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div class="netsuite-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="tokenSecret"
          rules="required"
          mode="eager"
        >
          <SlInput
            v-model="secretTokenModel"
            :label="$t('Web.Netsuite.LabelTokenSecret')"
            type="password"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter between>
        <SlLink
          :href="GUIDE_NETSUITE_CONNECTION_URL"
          target="_blank"
        >
          <template #prepend>
            <icon
              data="@icons/question.svg"
              class="fill-off size-20"
            />
          </template>
          {{ $t('Web.Netsuite.LinkDocumentation') }}
        </SlLink>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton @click="handleConnect">
            {{ $t('Web.DbImport.ButtonConnect') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import DatasourceConnectionError from '@/components/Modals/Connections/Connectors/Shared/DatasourceConnectionError';
import { GUIDE_NETSUITE_CONNECTION_URL } from '@/config/shared/resources.config';
import { dataFields } from '@/config/connection/dbRelated.config';
import { connectionData } from '@/config/connection/netsuite.config';
import modalsId from '@/config/shared/modalsId.config';
import { connectorRouteNames } from '@/config/router/router.config';

export default {
  name: 'NetsuiteConnectorModal',
  components: {
    DatasourceConnectionError
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      GUIDE_NETSUITE_CONNECTION_URL,
      isNew: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      selectedConnector: state => state.connection.selectedConnector,
      [dataFields.CONNECTION_DATA]: state => state.dbRelated[dataFields.CONNECTION_DATA],
      [dataFields.CONNECTION_DATA_CACHE]: state => state.dbRelated[dataFields.CONNECTION_DATA_CACHE]
    }),
    connectionDataKey() {
      return this.isNew
        ? dataFields.CONNECTION_DATA
        : dataFields.CONNECTION_DATA_CACHE;
    },
    companyModel: {
      get() {
        return this[this.connectionDataKey]?.company;
      },
      set(value) {
        this.setCurrentData({ key: 'company', value });
      }
    },
    consumerKeyModel: {
      get() {
        return this[this.connectionDataKey]?.consumerKey;
      },
      set(value) {
        this.setCurrentData({ key: 'consumerKey', value });
      }
    },
    consumerSecretKeyModel: {
      get() {
        return this[this.connectionDataKey]?.consumerSecretKey;
      },
      set(value) {
        this.setCurrentData({ key: 'consumerSecretKey', value });
      }
    },
    tokenModel: {
      get() {
        return this[this.connectionDataKey]?.token;
      },
      set(value) {
        this.setCurrentData({ key: 'token', value });
      }
    },
    secretTokenModel: {
      get() {
        return this[this.connectionDataKey]?.secretToken;
      },
      set(value) {
        this.setCurrentData({ key: 'secretToken', value });
      }
    }
  },
  methods: {
    ...mapActions({
      updateLocalConnectionData: 'dbRelated/updateLocalConnectionData',
      resetState: 'dbRelated/resetState',
      testConnection: 'netsuite/testConnection',
      setConnectionData: 'dbRelated/setConnectionData'
    }),
    onModalShow() {
      if (!this.isNew) {
        return this.setConnectionData({
          field: dataFields.CONNECTION_DATA_CACHE,
          value: this[dataFields.CONNECTION_DATA]
        });
      }

      this.setConnectionData({
        field: dataFields.CONNECTION_DATA,
        value: connectionData()
      });
    },
    onModalHide() {
      this.setConnectionData({
        field: this.isNew
          ? dataFields.CONNECTION_DATA
          : dataFields.CONNECTION_DATA_CACHE,
        value: {}
      });

      this.isNew = false;
      this.isLoading = false;
    },
    setCurrentData(payload) {
      this.updateLocalConnectionData({
        ...payload,
        dataKey: this.connectionDataKey
      });
    },
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      try {
        this.isLoading = true;

        await this.testConnection({
          connectionDataKey: this.connectionDataKey
        });

        if (this.isNew) {
          return this.$router.push({
            name: this.$sl_isEditingRoute
              ? connectorRouteNames.EDIT_CONNECTION_CONNECTOR
              : connectorRouteNames.CREATE_PROJECT_CONNECTOR,
            params: {
              connector: this.selectedConnector
            }
          });
        }

        await this.setConnectionData({
          field: dataFields.CONNECTION_DATA,
          value: this[dataFields.CONNECTION_DATA_CACHE]
        });

        this.hideModal(this.id);
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          connector: this.$t('Web.Modals.Error.TextConnectToDatasource'),
          error: e?.message
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.hideModal(this.id);

      if (this.isNew) {
        this.resetState();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection/connectors/netsuite/netsuite-connector-modal.scss";
</style>
