import { routeNames } from '../router/router.config';

export const linkItemsConfig = (context) => {
  return {
    demand: {
      to: '/demand',
      name: routeNames.DEMAND,
      icon: 'demand',
      label: context.$t('Main.tabNames.main')
    },
    inventory: {
      to: '/inventory',
      name: routeNames.INVENTORY,
      icon: 'inventory',
      label: context.$t('Main.tabNames.inventory')
    },
    optimization: {
      to: '/optimization',
      name: routeNames.INTERSTORE,
      icon: 'intersite',
      label: context.$t('Main.tabNames.transfers')
    },
    reports: {
      to: '/reports',
      name: routeNames.REPORTS,
      icon: 'reports',
      label: context.$t('Main.tabNames.report')
    },
    dashboard: {
      to: '/dashboards',
      name: routeNames.DASHBOARD,
      icon: 'dashboard',
      label: context.$t('Web.Dashboard.TabName')
    },
    changes: {
      to: '/changes',
      name: routeNames.CHANGES,
      icon: 'changes',
      label: context.$t('Main.tabNames.changes')
    }
  };
};
