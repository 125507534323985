<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
    @created="onCreated"
    @on-enter.stop="hide"
    @hide="onModalHide"
  >
    <div class="qb-error-modal modal-content">
      <icon
        data="@icons/style_error_double.svg"
        class="fill-off size-40"
      />
      <h2 class="heading-4-sb">
        {{ title }}
      </h2>
      <p
        v-if="text"
        class="body-1"
      >
        {{ text }}
      </p>
    </div>
    
    <template #footer>
      <SlModalFooter>
        <SlButton
          full-width
          @click.stop="hide"
        >
          {{ $t('Common.Ok') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'QBErrorModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      text: '',
      title: ''
    };
  },
  mounted() {
    this.hideModal(modalsId.QB_DESKTOP_LOADER);
  },
  methods: {
    onModalHide() {
      this.text = '';
    },
    hide() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/utils/color";
@import "@/style/utils/mixins";
@import "@/style/utils/variable";

.qb-error-modal.modal-content {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  p {
    text-align: center;
  }
}
</style>
