var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"title":_vm.$t('Web.Netsuite.ModalTitle'),"loading":_vm.isLoading,"persistent":""},on:{"on-enter":_vm.handleConnect,"created":_vm.onCreated,"show":_vm.onModalShow,"hidden":_vm.onModalHide},scopedSlots:_vm._u([{key:"modals",fn:function(){return [_c('DatasourceConnectionError',{attrs:{"id":_vm.modalsId.CONNECTOR_ERROR}})]},proxy:true},{key:"footer",fn:function(){return [_c('SlModalFooter',{attrs:{"between":""}},[_c('SlLink',{attrs:{"href":_vm.GUIDE_NETSUITE_CONNECTION_URL,"target":"_blank"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-20",attrs:{"data":require("@icons/question.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.Netsuite.LinkDocumentation'))+" ")]),_c('div',{staticClass:"sl-modal__footer-group"},[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleConnect}},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.ButtonConnect'))+" ")])],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",staticClass:"modal-content netsuite-connector"},[_c('div',{staticClass:"netsuite-connector__row"},[_c('SlValidate',{attrs:{"vid":"company","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Netsuite.LabelAccountId'),"is-invalid":invalid,"required":""},model:{value:(_vm.companyModel),callback:function ($$v) {_vm.companyModel=$$v},expression:"companyModel"}})]}}])})],1),_c('div',{staticClass:"netsuite-connector__row"},[_c('SlValidate',{attrs:{"vid":"consumerKey","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Netsuite.LabelConsumerKey'),"is-invalid":invalid,"required":""},model:{value:(_vm.consumerKeyModel),callback:function ($$v) {_vm.consumerKeyModel=$$v},expression:"consumerKeyModel"}})]}}])}),_c('SlValidate',{attrs:{"vid":"consumerSecret","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Netsuite.LabelConsumerSecret'),"type":"password","is-invalid":invalid,"required":""},model:{value:(_vm.consumerSecretKeyModel),callback:function ($$v) {_vm.consumerSecretKeyModel=$$v},expression:"consumerSecretKeyModel"}})]}}])})],1),_c('div',{staticClass:"netsuite-connector__row"},[_c('SlValidate',{attrs:{"vid":"token","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Netsuite.LabelTokenID'),"is-invalid":invalid,"required":""},model:{value:(_vm.tokenModel),callback:function ($$v) {_vm.tokenModel=$$v},expression:"tokenModel"}})]}}])})],1),_c('div',{staticClass:"netsuite-connector__row"},[_c('SlValidate',{attrs:{"vid":"tokenSecret","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Netsuite.LabelTokenSecret'),"type":"password","is-invalid":invalid,"required":""},model:{value:(_vm.secretTokenModel),callback:function ($$v) {_vm.secretTokenModel=$$v},expression:"secretTokenModel"}})]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }