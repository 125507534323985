<template>
  <SlModal
    :id="id"
    :title="$t('Main.Ui.acImportFromOdoo')"
    :loading="isLoading"
    :close-callback="handleCancel"
    @on-enter="handleConnect"
    @created="onCreated"
    @show="onModalShow"
    @hidden="onModalHide"
  >
    <template #modals>
      <DatasourceConnectionError :id="modalsId.CONNECTOR_ERROR" />
    </template>

    <ValidationObserver
      ref="observer"
      class="modal-content odoo-connector"
    >
      <div class="odoo-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          key="host"
          vid="host"
          rules="required"
        >
          <SlInput
            v-model="hostModel"
            :label="$t('DbInsideSourceWidget.Ui.lbHost')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
        <SlValidate
          v-slot="{ invalid }"
          vid="port"
          rules="required"
          class="odoo-connector__port"
        >
          <SlInput
            v-model="portModel"
            :label="$t('DbInsideSourceWidget.Ui.lbPort')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div class="odoo-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="databaseName"
          rules="required"
        >
          <SlInput
            v-model="databaseNameModel"
            :label="$t('DbInsideSourceWidget.Ui.lbDatabase')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div class="odoo-connector__row">
        <SlInput
          v-model="usernameModel"
          :label="$t('DbInsideSourceWidget.Labels.lbDbUsername')"
        />
        <SlPasswordInput
          v-model="passwordModel"
          :label="$t('DbInsideSourceWidget.Labels.lbDbPassword')"
        />
      </div>
      <div class="odoo-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="odooUrl"
          rules="required"
        >
          <SlInput
            v-model="odooUrlModel"
            :label="$t('DbInsideImport.Names.OdooServerUrl')"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
      <div class="odoo-connector__row">
        <SlValidate
          v-slot="{ invalid }"
          vid="odooUsername"
          rules="required"
        >
          <SlInput
            v-model="odooUsernameModel"
            :label="$t('DbInsideImport.Names.lbAPIUsername', { 1: 'Odoo' })"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
        <SlValidate
          v-slot="{ invalid }"
          vid="odooPassword"
          rules="required"
        >
          <SlPasswordInput
            v-model="odooPasswordModel"
            :label="$t('DbInsideImport.Names.lbAPIPassword', { 1: 'Odoo' })"
            :is-invalid="invalid"
            required
          />
        </SlValidate>
      </div>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter between>
        <SlLink
          :href="GUIDE_ODOO_CONNECTION_URL"
          target="_blank"
        >
          <template #prepend>
            <icon
              data="@icons/question.svg"
              class="fill-off size-20"
            />
          </template>
          {{ $t('Web.Netsuite.LinkDocumentation') }}
        </SlLink>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton @click="handleConnect">
            {{ $t('Web.DbImport.ButtonConnect') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import DatasourceConnectionError from '@/components/Modals/Connections/Connectors/Shared/DatasourceConnectionError';
import { GUIDE_ODOO_CONNECTION_URL } from '@/config/shared/resources.config';
import { dataFields } from '@/config/connection/dbRelated.config';
import modalsId from '@/config/shared/modalsId.config';
import { connectorRouteNames } from '@/config/router/router.config';

export default {
  name: 'OdooConnectorModal',
  components: {
    DatasourceConnectionError
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      GUIDE_ODOO_CONNECTION_URL,
      isNew: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      selectedConnector: state => state.connection.selectedConnector,
      [dataFields.CONNECTION_DATA]: state => state.dbRelated[dataFields.CONNECTION_DATA],
      [dataFields.CONNECTION_DATA_CACHE]: state => state.dbRelated[dataFields.CONNECTION_DATA_CACHE]
    }),
    connectionDataKey() {
      return this.isNew
        ? dataFields.CONNECTION_DATA
        : dataFields.CONNECTION_DATA_CACHE;
    },
    hostModel: {
      get() {
        return this[this.connectionDataKey]?.dbServer;
      },
      set(value) {
        this.setCurrentData({ key: 'dbServer', value });
      }
    },
    portModel: {
      get() {
        return this[this.connectionDataKey]?.port;
      },
      set(value) {
        this.setCurrentData({ key: 'port', value });
      }
    },
    databaseNameModel: {
      get() {
        return this[this.connectionDataKey]?.database;
      },
      set(value) {
        this.setCurrentData({ key: 'database', value });
      }
    },
    usernameModel: {
      get() {
        return this[this.connectionDataKey]?.username;
      },
      set(value) {
        this.setCurrentData({ key: 'username', value });
      }
    },
    passwordModel: {
      get() {
        return this[this.connectionDataKey]?.password;
      },
      set(value) {
        this.setCurrentData({ key: 'password', value });
      }
    },
    odooUrlModel: {
      get() {
        return this[this.connectionDataKey]?.odooUrl;
      },
      set(value) {
        this.setCurrentData({ key: 'odooUrl', value });
      }
    },
    odooUsernameModel: {
      get() {
        return this[this.connectionDataKey]?.odooUsername;
      },
      set(value) {
        this.setCurrentData({ key: 'odooUsername', value });
      }
    },
    odooPasswordModel: {
      get() {
        return this[this.connectionDataKey]?.odooPassword;
      },
      set(value) {
        this.setCurrentData({ key: 'odooPassword', value });
      }
    }
  },
  methods: {
    ...mapActions({
      updateLocalConnectionData: 'dbRelated/updateLocalConnectionData',
      resetState: 'dbRelated/resetState',
      testOdooConnection: 'dbRelated/testOdooConnection',
      setConnectionData: 'dbRelated/setConnectionData'
    }),
    onModalShow() {
      if (!this.isNew) {
        this.setConnectionData({
          field: dataFields.CONNECTION_DATA_CACHE,
          value: this[dataFields.CONNECTION_DATA]
        });
      }
    },
    onModalHide() {
      this.setConnectionData({
        field: this.isNew
          ? dataFields.CONNECTION_DATA
          : dataFields.CONNECTION_DATA_CACHE,
        value: {}
      });

      this.isNew = false;
      this.isLoading = false;
    },
    setCurrentData(payload) {
      this.updateLocalConnectionData({
        ...payload,
        dataKey: this.connectionDataKey
      });
    },
    async handleConnect() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      try {
        this.isLoading = true;

        await this.testOdooConnection({
          connectionDataKey: this.connectionDataKey
        });

        if (this.isNew) {
          return this.$router.push({
            name: this.$sl_isEditingRoute
              ? connectorRouteNames.EDIT_CONNECTION_CONNECTOR
              : connectorRouteNames.CREATE_PROJECT_CONNECTOR,
            params: {
              connector: this.selectedConnector
            }
          });
        }

        await this.setConnectionData({
          field: dataFields.CONNECTION_DATA,
          value: this[dataFields.CONNECTION_DATA_CACHE]
        });

        this.hideModal(this.id);
      } catch (e) {
        this.showModal(modalsId.CONNECTOR_ERROR, {
          connector: this.$t('Web.Modals.Error.TextConnectToDatasource'),
          error: e?.message
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.hideModal(this.id);

      if (this.isNew) {
        this.resetState();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.odoo-connector {
  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;

    margin-bottom: 12px;

    .odoo-connector__port {
      max-width: 120px;

      .sl-input-wrapper {
        min-width: 120px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}</style>