<template>
  <SlModal
    :id="id"
    :title="$t('Web.QBOnline.Title')"
    :loading="isLoading"
    persistent
    @on-enter="handleLogin"
    @created="onCreated"
    @hidden="onModalHide"
  >
    <template #modals>
      <ConnectionTimeoutModal :id="modalsId.QB_ONLINE_CONNECTION_TIMEOUT" />
      <OAuthLoginModal :id="modalsId.QB_ONLINE_OAUTH_LOGIN" />
    </template>

    <template #loader>
      <SlOverlay :show="isLoading" />
    </template>

    <div class="modal-content qb-online-connector body-1 grey-70">
      {{ $t('Web.QBOnline.oAuthTitle') }}
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          class="sl-button--qb-online"
          @click="handleLogin"
        >
          {{ $t('Web.QBOnline.BtnConnectToQBOnline' ) }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import { connection } from '@/mixins/connection';
import ConnectionTimeoutModal from '@/components/Modals/Shared/ConnectionTimeoutModal';
import OAuthLoginModal from '@/components/Modals/Shared/OAuthLoginModal';
import modalsId from '@/config/shared/modalsId.config';
import { sourceConnectDataFields, connectionTypes } from '@/config/connection';
import { operationInfoTypes } from '@/config/api/operations.config';
import { connectorRouteNames } from '@/config/router/router.config';
import { openLink } from '@/helpers/shared/webAPI';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'QBOnlineConnectorModal',
  components: {
    ConnectionTimeoutModal,
    OAuthLoginModal
  },
  mixins: [modal, connection],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      type: connectionTypes.QB_ONLINE,
      isNew: false,
      isLoading: false,
      oAuthLink: null,
      operationId: null
    };
  },
  methods: {
    ...mapActions({
      resetConnectionDataState: 'connection/resetConnectionDataState',
      fetchAccounts: 'qb/fetchAccounts',
      setAccounts: 'qb/setAccounts',
      subscribe: 'operations/subscribe',
      unsubscribe: 'operations/unsubscribe',
      logout: 'user/logout'
    }),
    onModalHide() {
      this.isNew = false;
      this.isLoading = false;
      this.oAuthLink = null;
      this.operationId = null;
    },
    handleLoginCancel() {
      this.unsubscribe(this.operationId);

      this.isLoading = false;
      this.oAuthLink = null;
      this.operationId = null;
    },
    handleLoginTryAgain() {
      if (this.oAuthLink) {
        openLink(this.oAuthLink, true);
      }
    },
    goToConnectorPage() {
      if (!this.isNew && !this.$sl_isEditingRoute) {
        this.resetConnectionDataState();

        this.setSourceConnectData({
          field: sourceConnectDataFields.MAIN,
          type: this.type,
          value: this.sourceConnectDataCache
        });
      }

      if (this.isNew) {
        return this.$router.push({
          name: connectorRouteNames.CREATE_PROJECT_CONNECTOR,
          params: {
            connector: this.type
          }
        });
      }

      if (this.$sl_isEditingRoute) {
        return this.$router.push({
          name: connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
          params: {
            connector: this.type
          }
        });
      }
    },
    async handleLogin() {
      try {
        this.isLoading = true;

        const { operationData } = await this.subscribe({
          subscriber: async() => {
            const response = await this.fetchAccounts();

            this.operationId = response?.data?.operationId;

            return response;
          },
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              this.isLoading = false;
              this.oAuthLink = operationData.url;
              openLink(operationData.url, true);

              this.showModal(modalsId.QB_ONLINE_OAUTH_LOGIN, {
                source: this.$t('Web.QBOnline.Title'),
                tryAgainCallback: this.handleLoginTryAgain,
                cancelCallback: this.handleLoginCancel
              });
            }
          }
        });

        if (!operationData) {
          return;
        }

        const { cacheId, payAccounts } = operationData;

        this.goToConnectorPage();

        this.setAccounts(toArray(payAccounts));

        this.setSourceConnectData({
          type: this.type,
          value: { cacheId }
        });

        this.hideModal(this.id);
      } catch (e) {
        if (e?.code === slErrorCodes.OAUTH_TIMEOUT_ERROR) {
          return this.showModal(modalsId.QB_ONLINE_CONNECTION_TIMEOUT, {
            source: this.$t('Web.QBOnline.Title'),
            closeCallback: () => this.hideModal(modalsId.QB_ONLINE_OAUTH_LOGIN)
          });
        }

        const message = e?.message;

        if (message) {
          this.$notify({
            type: 'error',
            title: message,
            duration: 15000
          });
        }

        this.hideModal(modalsId.QB_ONLINE_OAUTH_LOGIN);

        this.logout({
          e,
          from: 'qbOnline/handleLogin'
        });
      } finally {
        this.oAuthLink = null;
        this.operationId = null;
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
$qb-accent: #2CA01C;
$qb-shadow: 0 0 0 2px rgba($qb-accent, 0.6), 0 1px 2px rgba($qb-accent, 0.05);

.qb-online-connector.modal-content {
  min-height: fit-content;

  white-space: pre-line;
  overflow: visible;
}

::v-deep {
  .sl-button--qb-online {
    border: 1px solid $qb-accent;
    background: $qb-accent;
  }
  .sl-button--qb-online:hover,
  .sl-button--qb-online:focus {
    border: 1px solid darken($qb-accent, 3%);
    background: darken($qb-accent, 3%);
  }

  .sl-button--qb-online:focus {
    box-shadow: $qb-shadow;
  }

  .sl-button--qb-online:active {
    border: 1px solid darken($qb-accent, 5%);
    background: darken($qb-accent, 5%);
  }
}
</style>
