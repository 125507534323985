<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
  >
    <div class="modal-content">
      <SlLoader :text="false" />
      <h2 class="heading-4">
        {{ $t('Web.QB.TitleConnect') }}
      </h2>
      <p class="body-1">
        {{ $t('Web.QB.TextConnect') }}
      </p>
    </div>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'QBLoadingModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.QB_DESKTOP_LOADER
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/utils/color";
@import "@/style/utils/mixins";
@import "@/style/utils/variable";

.modal-content {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  p {
    text-align: center;
  }
}
</style>
