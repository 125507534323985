<template>
  <SidebarDropdown v-bind="$attrs">
    <template
      v-for="(_, slot) in $scopedSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
    <template #content>
      <div class="dropdown-menu">
        <div class="dropdown-menu__block">
          <div class="dropdown-menu__button">
            <SlCheckbox
              v-model="wantNoise"
              :label="$t('Main.TimeMachine.CheckStress')"
            />
          </div>
          <div class="dropdown-menu__row">
            <div>
              <div class="sl-input__label">
                <div class="sl-button__sign grey-80 body-2-md">
                  {{ $t('Main.TimeMachine.CurrentDate') }}
                </div>
                <div class="sl-input__label-description" />
              </div>
              <div
                class="dropdown-menu__label--borderless body-1-md"
              >
                {{ actualDateToShow }}
              </div>
            </div>
            <div>
              <SlSelect
                v-model="nSimulationDays"
                :options="steps"
                :select-label="$t('Main.TimeMachine.Step')"
                :max-height="stepMaxHeight"
                :append-to-body="false"
              />
            </div>
          </div>
          <div class="dropdown-menu__button">
            <SlButton
              class="dropdown-menu__button-travel"
              @click="travelTo"
            >
              {{ $t('Main.TimeMachine.TravelTo', { 1: dateToShow }) }}
            </SlButton>
          </div>

          <SidebarDropdownButton
            :label="$t('Main.Ui.acExportSimulationOrders')"
            icon="download"
            @click="exportOrders"
          />
        </div>
        <div class="dropdown-menu__block">
          <SidebarDropdownButton
            :label="$t('Main.TimeMachine.Stop')"
            icon="time-machine-stop"
            @click="handleTimeMachineStop"
          />
        </div>
      </div>
    </template>
  </SidebarDropdown>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SidebarDropdown from '@/components/SidebarMenu/Dropdown/SidebarDropdown.vue';
import SidebarDropdownButton from '@/components/SidebarMenu/Dropdown/SidebarDropdownButton.vue';
import SlButton from '@/components/UIKit/SlButton';
import SlSelect from '@/components/UIKit/SlSelect';
import SlCheckbox from '@/components/UIKit/SlCheckbox';
import { fileSaver } from '@/mixins/webAPI';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { localeSteps } from '@/config/timeMachine/timeMachine.config';
import { formatDayMonthYear } from '@/helpers/date/date';
import { toggleUserInteraction } from '@/helpers/shared/webAPI';
import { daysToMs } from '@/config/utils/dateInfo.config';

export default {
  name: 'TimeMachineDropdown',
  components: {
    SidebarDropdown,
    SidebarDropdownButton,
    SlCheckbox,
    SlButton,
    SlSelect
  },
  mixins: [fileSaver, modal],
  data() {
    return {
      calendar: false,
      simulationDay: null,
      nSimulationDays: 1,
      stepMaxHeight: 119
    };
  },
  computed: {
    ...mapState({
      actualDate: (state) => state.project.project?.actualDate,
      timeMachineStatus: (state) => state.timeMachine.status
    }),
    steps() {
      return localeSteps(this);
    },
    actualDateToShow() {
      return formatDayMonthYear(this.actualDate);
    },
    dateToShow() {
      const shiftMs = daysToMs(this.nSimulationDays);
      const currentDateMs = new Date(this.actualDate).valueOf();
      const newStop = currentDateMs + shiftMs;

      return formatDayMonthYear(newStop);
    },
    wantNoise: {
      get() {
        return this.timeMachineStatus.wantNoise;
      },
      set(value) {
        this.setStatus({
          wantNoise: value
        });
      }
    }
  },
  methods: {
    ...mapActions({
      stopTimeMachine: 'timeMachine/stop',
      travelAhead: 'timeMachine/travelAhead',
      exportOrdersToCsv: 'timeMachine/exportOrdersToCsv',
      setStatus: 'timeMachine/setStatus'
    }),
    handleTimeMachineStop() {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.ActionConfirm.TitleStopTimeMachine'),
        text: this.$t('Web.Modals.ActionConfirm.TextStopTimeMachine'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonStop'),
        confirmCallback: this.stopTimeMachine
      });
    },
    async travelTo() {
      try {
        toggleUserInteraction(true);

        await this.travelAhead({
          days: this.nSimulationDays,
          noise: this.wantNoise
        });
      } finally {
        toggleUserInteraction(false);
      }
    },
    exportOrders() {
      this.saveFile(this.exportOrdersToCsv);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/sidebar-menu/dropdown-menu.scss";
@import "@/style/components/sidebar-menu/time-machine-dropdown.scss";

.sl-button__sign {
  margin-bottom: 6px;
}
</style>