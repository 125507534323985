<template>
  <DefaultModal
    :id="id"
    width="400"
    :title="title"
    content-class="nested"
    :position="position"
    :close-callback="confirmCallback"
    @created="onCreated"
    @on-enter="handleConfirm"
  >
    <div class="notify">
      <div class="notify__text">
        {{ notifyText }}
      </div>
    </div>

    <template #footer>
      <v-btn
        class="sl-button"
        text
        @click="handleConfirm"
      >
        {{ $t('Common.Ok') }}
      </v-btn>
    </template>
  </DefaultModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import DefaultModal from '@/components/Modals/DefaultModal.vue';

export default {
  name: 'NotifyModal',
  components: {
    DefaultModal
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: false,
      default: 'top'
    }
  },
  data() {
    return {
      title: '',
      notifyText: '',
      confirmCallback: null
    };
  },
  methods: {
    onCreated({ title, text, confirmCallback }) {
      this.title = title;
      this.notifyText = text;
      this.confirmCallback = confirmCallback;
    },
    handleConfirm() {
      if (this.confirmCallback) {
        this.confirmCallback();
      }

      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/components/modals/notify-modal.scss";
</style>
