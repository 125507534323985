var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"loading":_vm.loading,"title":_vm.modalContent.title},on:{"on-enter":_vm.handleConfirm,"created":_vm.onCreated,"hidden":_vm.setDefaultValues,"hide":_vm.onHide},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('SlModalFooter',[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleConfirm}},[_vm._v(" "+_vm._s(_vm.$t('Web.ReportIssue.Send'))+" ")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",staticClass:"modal-content report-issue"},[(_vm.needLogs)?_c('SlValidate',{key:"agreeSend",attrs:{"vid":"agreeSend","rules":{
        required: {
          message: _vm.$t('Web.ReportIssue.AgreeSendValidation')
        }
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlCheckbox',{attrs:{"not-selected":null,"label":_vm.$t('Web.ReportIssue.AgreeSend'),"is-invalid":invalid},model:{value:(_vm.agreeSend),callback:function ($$v) {_vm.agreeSend=$$v},expression:"agreeSend"}})]}}],null,false,3706901593)}):_vm._e(),_c('SlValidate',{key:"username",attrs:{"vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.ReportIssue.Name'),"required":"","is-invalid":invalid},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])}),_c('SlValidate',{key:"port",staticClass:"database-connector__port",attrs:{"vid":"port","rules":"required|email","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validate = ref.validate;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.ReportIssue.Email'),"required":"","is-invalid":invalid,"validator":validate},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})]}}])}),_c('SlValidate',{key:"userIssue",staticClass:"database-connector__port",attrs:{"vid":"userIssue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlTextArea',{attrs:{"is-invalid":invalid,"label":_vm.modalContent.description,"maxlength":9999,"height":"96","required":"","bottom-round":"","autofocus":""},model:{value:(_vm.userIssue),callback:function ($$v) {_vm.userIssue=$$v},expression:"userIssue"}})]}}])}),_c('SlValidate',{attrs:{"vid":"file","rules":"sl_file_size:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validate = ref.validate;
return [_c('div',{staticClass:"body-2-md grey-80 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('Web.ReportIssue.Attachment'))+" ")]),_c('SlDropzone',{ref:"dropzone",attrs:{"exist-file-names":_vm.uploadedFileNames,"accepted":"*/*","max-files":_vm.MAX_FILES,"tooltip-max-files":_vm.MAX_FILES,"upload-callback":_vm.uploadFile,"validator":validate,"is-invalid":invalid},on:{"queue-upload-start":function($event){return _vm.setLoading(true)},"queue-upload-end":function($event){return _vm.setLoading(false)},"file-removed":_vm.handleRemoveFile}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }