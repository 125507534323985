export default function(files) {
  if (!files.length) {
    return null;
  }

  return {
    spreadsheetConnectors: {
      connector: [{
        dataSource: '',
        files: {
          _n: files.length,
          file: files.map(file => ({ _fileId: file.fileId }))
        }
      }]
    }
  };
}